<template>
  <v-card class="mx-auto mt-5 mb-5" elevation="4" max-width="600">
    <v-card-title class="justify-center">Account</v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-card class="mb-5" elevation="2">
        <v-card-text>
          <v-form ref="form" class="pt-1" v-model="valid">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="user.first_name"
                  :rules="req"
                  label="First name"
                  dense
                  :loading="loading"
                  :disabled="loading"
                  data-cy="user.first_name-input"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="user.last_name"
                  :rules="req"
                  label="Last name"
                  dense
                  :loading="loading"
                  :disabled="loading"
                  data-cy="user.last_name-input"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="user.email"
                  label="E-mail"
                  placeholder="E-mail"
                  dense
                  :loading="loading"
                  :disabled="loading"
                  :rules="req"
                  data-cy="user.email-input"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="user.phone"
                  label="Phone Number"
                  type="tel"
                  dense
                  :loading="loading"
                  :disabled="loading"
                  :rules="req"
                  autocomplete="tel"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="user.street"
                  label="Address Line"
                  autocomplete="shipping street-address"
                  dense
                  :loading="loading"
                  :disabled="loading"
                  :rules="req"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="user.city"
                  label="City"
                  dense
                  :loading="loading"
                  :disabled="loading"
                  :rules="req"
                  autocomplete="shipping locality"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-select
                  v-model="user.state"
                  :items="all_states"
                  label="State"
                  item-value="abbreviation"
                  item-text="name"
                  dense
                  :loading="loading"
                  :disabled="loading"
                  autocomplete="shipping region"
                ></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="user.zip"
                  label="ZIP / Postal Code"
                  dense
                  :loading="loading"
                  :disabled="loading"
                  :rules="req"
                  autocomplete="shipping postal-code"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn @click="themeSwitch()" v-if="$vuetify.theme.dark">
            Light Mode
          </v-btn>
          <v-btn @click="themeSwitch()" v-else>Dark Mode</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="update"> Update </v-btn>
        </v-card-actions>
      </v-card>

      <v-card class="mt-5 mb-5" elevation="4">
        <v-card-title class="justify-center"> Reset Password </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="newPassword"
                label="New Password"
                type="password"
                dense
                :loading="loading"
                :disabled="loading"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="confirmPassword"
                label="Confirm Password"
                type="password"
                dense
                :loading="loading"
                :disabled="loading"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="reset"> Reset </v-btn>
        </v-card-actions>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import hasuraService from "@/services/hasura.service.js";

export default {
  name: "Account",
  mixins: [hasuraService],

  // components: {
  // },
  mounted() {
    // this.getUser();
    this.get_user();
  },

  methods: {
    async copyURL() {
      await navigator.clipboard.writeText(this.$store.state.user.id);
      this.$store.commit("set_snackbar", "Copied to clipboard!");
    },
    async get_user() {
      this.loading = true;
      this.user = await this.get_user_by_pk(this.$store.state.user.id);
      this.loading = false;
    },
    async reset() {
      if (this.newPassword == this.confirmPassword) {
        this.loading = true;
        let result = await this.passwordChangeAccount(this.newPassword);
        this.loading = false;
        if (result.ok) {
          // success
          this.$store.commit("set_snackbar", "Password Updated!");
        } else {
          // error
          this.$store.commit(
            "set_snackbar",
            "Server Error, please contact matt@annuitycheck.com"
          );
        }
      } else {
        // bad password
        this.$store.commit(
          "set_snackbar",
          "Passwords do not match, please try again"
        );
      }
    },
    update: async function () {
      if (this.$refs.form.validate()) {
        this.loading = true;
        await this.update_user_by_pk(this.$store.state.user.id, this.user);
        this.$store.commit("set_snackbar", "Saved!");
        this.loading = false;
      }
    },
    themeSwitch() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.dark = this.$vuetify.theme.dark + "";
    },
  },
  computed: {
    primary_color: {
      get() {
        return localStorage.primary_color || "#22ABE1";
      },
      set(value) {
        localStorage.primary_color = value;
        this.$vuetify.theme.themes.light.primary = value;
        this.$vuetify.theme.themes.dark.primary = value;
      },
    },
  },

  data() {
    return {
      req: [(v) => !!v || "Field is required"],
      loading: true,
      newPassword: "",
      confirmPassword: "",
      valid: true,
      user: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        street: "",
        city: "",
        state: "",
        zip: "",
      },
    };
  },
};
</script>
